import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTrash, FaSave } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast'; // Import react-hot-toast

const Term = () => {
  const [content, setContent] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null); // State for last updated date
  const user = useSelector((state) => state?.user?.user);

  // Load existing content on mount
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/getrefund?owner=${user?._id}`);
        const data = await response.json();
        if (data) {
          setContent(data.content);
          setLastUpdated(data.lastUpdated); // Set last updated time
        }
      } catch (error) {
        toast.error('Error fetching content'); // Show error notification
      }
    };
    fetchContent();
  }, [user?._id]);

  // Format last updated date for display
  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // Upsert content
  const handleSave = async () => {
    try {
      const response = await fetch('https://builder.biztoindia.in/api/upsertrefund', {
        method: 'POST',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      const data = await response.json();
      if (response.ok) {
        setLastUpdated(data.lastUpdated); // Update last updated after saving
        toast.success('Content saved successfully!'); // Show success notification
      } else {
        toast.error('Failed to save content'); // Show error notification
      }
    } catch (error) {
      toast.error('Error saving content'); // Show error notification
    }
  };

  // Delete content
  const handleDelete = async () => {
    try {
      const response = await fetch('https://builder.biztoindia.in/api/deleterefund', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ owner: user?._id }),
      });

      if (response.ok) {
        setContent('');
        setLastUpdated(null); // Reset last updated after deletion
        toast.success('Content deleted successfully!'); // Show success notification
      } else {
        toast.error('Failed to delete content'); // Show error notification
      }
    } catch (error) {
      toast.error('Error deleting content'); // Show error notification
    }
  };

  return (
    <div className="p-6">
      <Toaster /> {/* Add Toaster component to enable notifications */}

      {/* Title */}
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Refund Policy {/* Title for the page */}
      </h1>

      {/* Text Editor */}
      <ReactQuill 
        value={content} 
        onChange={setContent}  
        modules={quillModules} 
        theme="snow"
        placeholder="Let your creativity flow! Start typing something awesome..." // Add awesome placeholder text
      />

      {lastUpdated && (
        <p className="text-sm text-gray-500 mt-2">
          Last updated on: {formatDate(lastUpdated)}
        </p>
      )}

      {/* Action Buttons */}
      <div className="flex justify-between mt-6">
        <button 
          onClick={handleSave} 
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded shadow-md hover:bg-blue-600 transition-transform transform hover:scale-105"
        >
          <FaSave className="mr-2" /> Save
        </button>
        <button 
          onClick={handleDelete} 
          className="flex items-center bg-red-500 text-white px-4 py-2 rounded shadow-md hover:bg-red-600 transition-transform transform hover:scale-105"
        >
          <FaTrash className="mr-2" /> Delete
        </button>
      </div>
    </div>
  );
};

export default Term;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ size: ["small", "medium", "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "image", "video"], // Added image and video options
    ["clean"], // Remove formatting
  ],
};

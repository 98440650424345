import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ROLE from "../common/role";
import { setUserDetails } from "../store/userSlice";
import SummaryApi from "../common";
import { toast } from "react-toastify";
import { FaBars, FaTimes, FaCog, FaInfoCircle, FaEnvelope, FaUsers } from "react-icons/fa"; // Example icons
import { Helmet } from "react-helmet";
import { 
  FaPalette, 
  FaShoppingCart, 
  FaUpload, 
  FaImage, 
  FaImages, 
  FaPen, 
  FaMapMarkerAlt, 
  FaEnvelopeOpen, 
  FaComments, 
  FaQuestionCircle, 
  FaShieldAlt, 
  FaLock, 
  FaEdit, 
  FaStar, 
  FaFileContract, 
  FaTruck, 
  FaUndo, 
  FaUserFriends 
} from 'react-icons/fa';

const AdminPanel = () => {
  const [localLoading, setLocalLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 

  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLocalLoading(true);
        const dataResponse = await fetch(SummaryApi.current_user.url, {
          method: SummaryApi.current_user.method,
          credentials: "include",
        });

        const dataApi = await dataResponse.json();

        if (dataApi.success) {
          dispatch(setUserDetails(dataApi.data));
        }
      } catch (error) {
        toast.error("Failed to fetch user details");
      } finally {
        setLocalLoading(false);
      }
    };

    fetchUserDetails();
  }, [dispatch]);

  useEffect(() => {
    if (!localLoading) {
      if (user?.role === ROLE.SUPERADMIN) {
        navigate("/superadmin");
      } else if (user?.role !== ROLE.ADMIN) {
        navigate("/");
      }
    }
  }, [user, localLoading, navigate]);

  const handleLogout = async () => {
    try {
      const fetchData = await fetch(SummaryApi.logout_user.url, {
        method: SummaryApi.logout_user.method,
        credentials: "include",
      });

      const data = await fetchData.json();

      if (data.success) {
        toast.success(data.message);
        dispatch(setUserDetails(null));
        navigate("/");
      }

      if (data.error) {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Logout failed. Please try again.");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (localLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-[auto,1fr] min-h-screen relative overflow-hidden">
      <button
        className="absolute left-4 z-20 md:hidden"
        onClick={toggleSidebar}
        style={{ marginTop: "100px" }}
      >
        {isSidebarOpen ? <FaTimes className="w-8 h-8 text-gray-800" /> : <FaBars className="w-8 h-8 text-gray-800" />}
      </button>

      <aside
        className={`bg-yellow-500 w-full md:w-64 lg:w-80 p-4 shadow-md md:block h-screen fixed md:relative z-10 transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"
        }`}
      >
        <div className="flex flex-col h-full">
          <nav className="space-y-4 flex-grow overflow-y-auto">
            {[
              { to: "body-color", label: "Body Color Variant", icon: <FaPalette /> },
              { to: "footer-color", label: "Header Color Variant", icon: <FaCog /> },
              { to: "header-color", label: "Footer Color Variant", icon: <FaCog /> },
              { to: "all-enquiries", label: "All Enquiries", icon: <FaEnvelope /> },
              { to: "all-category", label: "All Categories", icon: <FaUsers /> },
              { to: "all-products", label: "All Products", icon: <FaShoppingCart /> },
              { to: "logo-update", label: "Update Logo", icon: <FaUpload /> },
              { to: "banner-update", label: "Update Banner", icon: <FaImage /> },
              { to: "gallery-update", label: "Update Gallery", icon: <FaImages /> },
              { to: "create-blog", label: "Create Blog", icon: <FaPen /> },
              { to: "about-us", label: "About Us", icon: <FaInfoCircle /> },
              { to: "contact-in", label: "Google Map / Address / Contact", icon: <FaMapMarkerAlt /> },
              { to: "email-in", label: "Email Integration", icon: <FaEnvelopeOpen /> },
              { to: "settings", label: "WhatsApp/Call Integration", icon: <FaCog /> },
              { to: "whatsapp-api", label: "WhatsApp API Integration", icon: <FaComments /> },
              { to: "FAQ", label: "FAQ", icon: <FaQuestionCircle /> },
              { to: "PRIVACY", label: "Update Privacy & Policy", icon: <FaShieldAlt /> },
              { to: "permis", label: "Permission-other Pages", icon: <FaLock /> },
              { to: "header-update", label: "Rename Products", icon: <FaEdit /> },
              { to: "testimonials", label: "Add Testimonials", icon: <FaStar /> },
              { to: "terms", label: "Terms Of Use", icon: <FaFileContract /> },
              { to: "shipping", label: "Shipping and Return Policy", icon: <FaTruck /> },
              { to: "return", label: "Refund Policy", icon: <FaUndo /> },
              { to: "clients", label: "Our Clients", icon: <FaUserFriends /> },
            ].map((item, index) => (
              <div key={index}>
                <Link
                  to={item.to}
                  className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300 bg-gray-100"
                  style={{ marginTop: index === 0 ? "80px" : "0" }}
                  onClick={toggleSidebar}
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </Link>
              </div>
            ))}
          </nav>

          <div className="w-full mt-4">
            <button
              onClick={handleLogout}
              className="w-full px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg transition duration-300"
            >
              Logout
            </button>
          </div>
        </div>
      </aside>

      <main
        style={{
          flex: 1,
          backgroundColor: "#f7fafc",
          transition: "all 0.3s",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <div className="mt-20 md:mt-20 md:ml-0 ml-20">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default AdminPanel;

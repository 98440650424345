import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
import Login from '../pages/Login'
import ForgotPassowrd from '../pages/ForgotPassowrd'
import SignUp from '../pages/SignUp'
import AdminPanel from '../pages/AdminPanel'
import AllUsers from '../pages/AllUsers'
import AllProducts from '../pages/AllProducts'
import CategoryProduct from '../pages/CategoryProduct'
import ProductDetails from '../pages/ProductDetails'
import Cart from '../pages/Cart'
import SearchProduct from '../pages/SearchProduct'
import AboutUs from '../pages/AboutUs'
import ContactUs from '../pages/ContactUs'
import ShowAllProducts from '../pages/ShowAllProducts'
import UploadLogo from '../components/UploadLogo'
import BannerUpdate from '../components/BannerUpdate'
import EmailIntegration from "../pages/EmailIntegration"
import UploadGallery from '../pages/UploadGallery'
import ShowGallery from '../components/ShowGallery'
import CreateBlog from '../pages/CreateBlog'
import BlogList from '../components/BlogList'
import BlogDetails from '../pages/BlogDetails'
import UpdateAbout from '../pages/UpdateAbout'
import AllCategoryUpdate from '../pages/AllCategoryUpdate'
import ContactIntegreation from "../pages/ContactIntegreation"
import WhatsappAndCallIntegration from "../pages/WhatsappAndCallIntegration"
import FaqIntegration from "../pages/FaqIntegration"
import FaqShow from '../components/FaqShow'
import GrantingPermission from "../pages/GrantingPermission"
import PrivacyAndPolicy from "../pages/PrivacyAndPolicy"
import PrivacyPolicyShow from '../components/PrivacyPolicyShow'
import Enquiries from '../pages/Enquiries'
import SuperAdmin from '../pages/SuperAdmin'
import HeaderUpdate from '../pages/HeaderUpdate'
import HeaderColorPallet from '../pages/HeaderColorPallet'
import FooterColorPallet from "../pages/FooterColorPallet"
import BodyColorPallte from '../pages/BodyColorPallte'
import WhatsAppApiIntegreation from '../pages/WhatsAppApiIntegreation'
import Dashboard from '../pages/Dashboard'
import Term from '../pages/Term'
import Shipping from '../pages/Shipping'
import Return from '../pages/Return'
import TestimonialsAdmin from '../pages/TestimonialsAdmin'
import OurClients from '../pages/OurClients'

const router = createBrowserRouter([
    {
        path : "/",
        element : <App/>,
        children : [
            {
                path : "",
                element : <Login/>
            },
           
            {
                path : "privacy-policy",
                element : <PrivacyPolicyShow/>
            },
            {
                path : "login",
                element : <Login/>
            },
            {
                path : "forgot-password",
                element : <ForgotPassowrd/>
            },
            {
                path : "sign-up",
                element : <SignUp/>
            },
            {
                path : "faq",
                element : <FaqShow/>
            },
            {
                path : "product-category",
                element : <CategoryProduct/>
            },
            {
                path : "product/:id",
                element : <ProductDetails/>
            },
            {
                path:"blogs/:id",
                element:<BlogDetails/>
            },
            {
                path : 'cart',
                element : <Cart/>
            },
            {
                path : 'gallery',
                element : <ShowGallery/>
            },
            {
                path : "search",
                element : <SearchProduct/>
            },
            {
                path:"about",
                element:<AboutUs/>
            },
            {path:"blogs",
                element:<BlogList/>
            },
            {
                path:"contact",
                element:<ContactUs/>
            },
            {
                path:"all-products",
                element:<ShowAllProducts/>
            },
           
            {
                path : "admin",
                element : <AdminPanel/>,
                children : [
                    {
                        path : "all-users",
                        element : <AllUsers/>
                    },
                    {
                        path : "all-products",
                        element : <AllProducts/>
                    },
                    {
                        path:"logo-update",
                        element:<UploadLogo/>
                    },
                    {
                        path:"banner-update",
                        element:<BannerUpdate/>
                    },
                    {
                        path:"gallery-update",
                        element:<UploadGallery />
                    },
                    {
                        path:"create-blog",
                        element:<CreateBlog/>
                    },
                    {
                        path:"about-us",
                        element:<UpdateAbout/>
                    },
                    {
                        path: "all-category",
                        element:<AllCategoryUpdate/>
                    },
                    {
                        path: "testimonials",
                        element:<TestimonialsAdmin/>
                    },
                    {
                        path: "email-in",
                        element:<EmailIntegration/>
                    },
                    {
                        path:  "contact-in",
                        element:<ContactIntegreation/>
                    },
                    {
                        path:  "settings",
                        element:<WhatsappAndCallIntegration/>
                    },
                    {
                        path:  "FAQ",
                        element:<FaqIntegration/>
                    },
                    {
                        path:  "permis",
                        element:<GrantingPermission/>
                    },
                    {
                        path:  "PRIVACY",
                        element:<PrivacyAndPolicy/>
                    },
                    {
                        path:  "all-enquiries",
                        element:<Enquiries/>
                    },
                    {
                        path:"header-update",
                        element:<HeaderUpdate/>
                    },
                    {
                        path:"header-color",
                        element:<HeaderColorPallet/>
                    }
              
                    ,
                    {
                        path:"footer-color",
                        element:<FooterColorPallet/>
                    },
,
                    {
                        path:"body-color",
                        element:<BodyColorPallte/>
                    },
                    {
                        path:"body-color",
                        element:<BodyColorPallte/>
                    },
                    {
                        path:"whatsapp-api",
                        element:<WhatsAppApiIntegreation/>
                    },
                    {
                        path:"",
                        element:<Dashboard/>
                    },
                    {
                        path:"dashboard",
                        element:<Dashboard/>
                    },
                    {
                        path:"dashboard",
                        element:<Dashboard/>
                    },
                    {
                        path:"terms",
                        element:<Term/>
                    },
                    {
                        path:"shipping",
                        element:<Shipping/>
                    },
                    {
                        path:"return",
                        element:<Return/>
                    },
                    {
                        path:"clients",
                        element:<OurClients/>
                    }


                ]
            },
            {
                path : "superadmin",
                element : <SuperAdmin/>,
                children : [
                    {
                        path : "all-users",
                        element : <AllUsers/>
                    }
                     
                   
                ]
            },
        ]
    }
])


export default router
import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';

const BannerUpdate = () => {
  const [desktopImages, setDesktopImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);

  useEffect(() => {
    // Fetch existing images for the user when component mounts
    const fetchImages = async () => {
      try {
        const response = await fetch('https://builder.biztoindia.in/api/get-banner-images', {
          credentials: 'include',
          method: 'GET',
        });

        const data = await response.json();
        if (response.ok) {
          setDesktopImages(data.desktopImages || []);
          setMobileImages(data.mobileImages || []);
        } else {
          toast.error(data.message || 'Failed to fetch images.');
        }
      } catch (error) {
        toast.error('An error occurred while fetching images.');
        console.error(error);
      }
    };

    fetchImages();
  }, []);

  const handleFileChange = (e, setImage) => {
    setImage(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (desktopImages.length === 0 || mobileImages.length === 0) {
      toast.error('Please upload both desktop and mobile images.');
      return;
    }

    const formData = new FormData();
    desktopImages.forEach(file => formData.append('desktopImages', file));
    mobileImages.forEach(file => formData.append('mobileImages', file));

    try {
      const response = await fetch('https://builder.biztoindia.in/api/upload-banner', {
        credentials: 'include',
        method: 'POST',
        body: formData, // Send formData directly
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Images uploaded and saved successfully.');
        setDesktopImages([]);
        setMobileImages([]);
      } else {
        toast.error(data.message || 'Failed to save images. Please try again.');
      }

    } catch (error) {
      toast.error('An error occurred during upload. Please check your connection and try again.');
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch('https://builder.biztoindia.in/api/delete-images', {
        credentials: 'include',
        method: 'DELETE',
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Images deleted successfully.');
        setDesktopImages([]);
        setMobileImages([]);
      } else {
        toast.error(data.message || 'Failed to delete images. Please try again.');
      }

    } catch (error) {
      toast.error('An error occurred while deleting images.');
      console.error(error);
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow-lg rounded-lg border border-gray-200 mt-[50%] md:mt-[10%]">
      <Toaster position="top-right" reverseOrder={false} />
      <h1 className="text-3xl font-bold mb-4 text-center text-gray-800">Update Your Banners</h1>
      <p className="text-gray-600 text-center mb-4">
        Choose eye-catching images for both desktop and mobile views to make your site stand out!
      </p>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col space-y-3">
          <label htmlFor="desktopImages" className="font-medium text-gray-700">Desktop Images:</label>
          <input
            type="file"
            id="desktopImages"
            accept="image/*"
            multiple
            onChange={(e) => handleFileChange(e, setDesktopImages)}
            className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="grid grid-cols-2 gap-2">
            {desktopImages.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Desktop Preview ${index + 1}`}
                className="w-full h-24 object-cover rounded border border-gray-300 shadow"
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <label htmlFor="mobileImages" className="font-medium text-gray-700">Mobile Images:</label>
          <input
            type="file"
            id="mobileImages"
            accept="image/*"
            multiple
            onChange={(e) => handleFileChange(e, setMobileImages)}
            className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="grid grid-cols-2 gap-2">
            {mobileImages.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Mobile Preview ${index + 1}`}
                className="w-full h-24 object-cover rounded border border-gray-300 shadow"
              />
            ))}
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition shadow"
        >
          Upload Images
        </button>
        <button
          type="button"
          onClick={handleDelete}
          className="w-full py-3 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition shadow"
        >
          Delete Existing Images and Hide from Website
        </button>
      </form>
    </div>
  );
};

export default BannerUpdate;

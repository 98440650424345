import logo from './logo.svg';
import './App.css';
import { Outlet,useLocation  } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import SummaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './store/userSlice';
import ContactForm from './components/Enquiry';
import AdminHeader from "./components/AdminHeader.js"
function App() {
  const dispatch = useDispatch()
  const [cartProductCount,setCartProductCount] = useState(0)
  const location= useLocation();
  const fetchUserDetails = async()=>{
      const dataResponse = await fetch(SummaryApi.current_user.url,{
        method : SummaryApi.current_user.method,
        credentials : 'include'
      })

      const dataApi = await dataResponse.json()
  
      if(dataApi?.success){
        dispatch(setUserDetails(dataApi?.data))
      }
  }

  const fetchUserAddToCart = async()=>{
    const dataResponse = await fetch(SummaryApi.addToCartProductCount.url,{
      method : SummaryApi.addToCartProductCount.method,
      credentials : 'include'
    })

    const dataApi = await dataResponse.json()
    
    setCartProductCount(dataApi?.data?.count)
  }

  useEffect(()=>{
    /**user Details */
    fetchUserDetails()
    /**user Details cart product */
    fetchUserAddToCart()

  },[])
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isSuperAdminRoute= location.pathname.startsWith('/superadmin')
  return (
    <>
      <Context.Provider value={{
          fetchUserDetails, // user detail fetch 
          cartProductCount, // current user add to cart product count,
          fetchUserAddToCart
      }}>
        <ToastContainer 
          position='top-center'
        />
        
       
      {isAdminRoute  && <AdminHeader />}
      {isSuperAdminRoute  && <AdminHeader />}
 
 
      <main className={`min-h-[calc(100vh-120px)] ${isAdminRoute ? '' : 'pt-16'}`}>
        <Outlet />
      </main>
 
 
      </Context.Provider>
    </>
  );
}

export default App;

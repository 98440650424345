import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaEye } from "react-icons/fa"; // Import Eye icon
import { Link } from "react-router-dom";
import Logo from "../assest/bizto.jpeg";

const AdminHeader = () => {
  const user = useSelector((state) => state?.user?.user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      {/* Header */}
      <header className="fixed top-0 left-0 w-full flex items-center justify-between p-2 bg-gray-50 shadow-lg z-50">
        {/* Logo or Brand Name */}
        <div className="flex items-center">
          <Link to="/admin/dashboard" className="hover:text-blue-600 transition duration-200">
            <img
              src={Logo}
              alt="Logo"
              className="h-12 mr-4"
            />
          </Link>
          <div className="text-2xl font-bold text-gray-800">
            <Link to="/admin" className="hover:text-blue-600 transition duration-200">
              Admin Panel
            </Link>
          </div>
        </div>

        {/* User Profile */}
        <div className="flex items-center space-x-4">
          {/* Optional User Image */}
          {/* {user?.profilePic ? (
            <img
              src={user?.profilePic}
              alt={user?.name}
              className="w-12 h-12 rounded-full border-2 border-blue-500 shadow-sm hover:scale-105 transition-transform duration-300"
            />
          ) : (
            <FaRegCircleUser className="text-4xl text-blue-500 hover:scale-105 transition-transform duration-300" />
          )} */}

          {/* User Info */}
          <div className="text-right ml-5">
            <p className="text-lg font-semibold text-gray-700 capitalize animate-fadeIn">
              👋 Welcome, <span className="text-blue-500">{user?.name || "Guest"}</span>
            </p>
            <button
              className="mt-2 flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 transition duration-300 hover:shadow-lg transform hover:scale-105"
              onClick={toggleModal}
            >
              <FaEye className="mr-2" /> View Details
            </button>
          </div>
        </div>
      </header>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-w-md w-full transform transition-all duration-300 scale-100 hover:scale-105">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">{user?.role} Details</h2>
            <div className="space-y-4">
              <p>
                <strong className="font-semibold text-gray-700">Name:</strong> {user?.name || "Not Provided"}
              </p>
              <p>
                <strong className="font-semibold text-gray-700">Email:</strong> {user?.email || "Not Provided"}
              </p>
              <p>
                <strong className="font-semibold text-gray-700">Phone:</strong> {user?.phoneNumber || "Not Added"}
              </p>
              <p>
                <strong className="font-semibold text-gray-700">Role:</strong> {user?.role || "User"}
              </p>
            </div>

            {/* Close button */}
            <button
              className="mt-6 px-6 py-3 text-sm font-medium text-white bg-red-500 rounded-lg shadow-md hover:bg-red-600 hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
              onClick={toggleModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminHeader;

 
import { FaChartLine, FaEnvelope, FaList, FaBoxes, FaPhoneAlt, FaGlobe } from 'react-icons/fa'; // For icons
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaCloudUploadAlt } from 'react-icons/fa';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import uploadImage from '../helpers/uploadImage';
const Dashboard = () => {
  const [categories, setCategories] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const user = useSelector(state => state?.user?.user);
  const [enquiries, setEnquiries] = useState([]);
  const [recentEnquiries, setrecentEnquiries] = useState([])
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SummaryApi.allcategory.url}/${user?._id}`, {
          method: SummaryApi.allcategory.method,
          credentials: 'include',
          headers: { "Content-Type": "application/json" }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.success) {
          setCategories(responseData.categories);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        // toast.error("You don't have any existing categories");
      }
    };

    fetchCategories();
  }, [user?._id]);
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-enquiry/${user?._id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const last24Hours = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const recentEnquiries = data?.data?.filter(enquiry => {
          const enquiryDate = new Date(enquiry.createdAt); // Adjust `createdAt` to your actual date field name
          return enquiryDate >= last24Hours;
        });
        setrecentEnquiries(recentEnquiries);
        setEnquiries(data.data);
       
      } catch (error) {
 
      }
    };

    fetchEnquiries();
  }, [user?._id]);

  const fetchAllProduct = async () => {
    try {
      const response = await fetch(`${SummaryApi.allProduct.url}/${user._id}`);
      const dataResponse = await response.json();
      setAllProduct(dataResponse?.data || []);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);


  return (
    <div className="p-4 md:p-8 bg-white min-h-screen">
      {/* Set page title */}
      <Helmet>
        <title>Admin Dashboard | BizToIndia</title>
      </Helmet>

      {/* Welcome section */}
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Welcome to BizToIndia Admin Dashboard</h1>
        <p className="text-gray-600 mt-2">Manage all aspects of the platform from here</p>
      </div>

      {/* Overview cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-yellow-400 text-white rounded-lg p-6 flex items-center">
          <FaChartLine className="w-8 h-8 mr-4" />
          <div>
            <h2 className="text-lg font-bold">Total Enquiries</h2>
            <p className="text-xl">{categories.length || '0'}</p>
          </div>
        </div>
        <div className="bg-blue-500 text-white rounded-lg p-6 flex items-center">
          <FaList className="w-8 h-8 mr-4" />
          <div>
            <h2 className="text-lg font-bold">Categories</h2>
            <p className="text-xl">{categories?.length || '0'}</p>
          </div>
        </div>
        <div className="bg-green-500 text-white rounded-lg p-6 flex items-center">
          <FaBoxes className="w-8 h-8 mr-4" />
          <div>
            <h2 className="text-lg font-bold">Total Products</h2>
            <p className="text-xl">{allProduct.length || '0'}</p>
          </div>
        </div>
      </div>

      {/* Recent Activities */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-gray-800">Last 24 hours Enquiries</h2>
        <div className="mt-4 bg-gray-100 p-4 rounded-lg">
          <p>{recentEnquiries?.length}</p>
        </div>
      </div>

      {/* Website and Contact Information */}
      <div className="mt-12 bg-gray-100 p-6 rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800"> Need Help ?</h2>

        {/* Website Link */}
        <div className="mt-4 flex items-center">
          <FaGlobe className="w-6 h-6 text-gray-700 mr-2" />
          <a
            href="https://www.biztoindia.com" // Replace with your actual website link
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.biztoindia.com
          </a>
        </div>

        {/* Contact Details */}
        <div className="mt-6 flex items-center">
          <FaPhoneAlt className="w-6 h-6 text-gray-700 mr-2" />
          <p className="text-gray-700">
          +91 9910443978</p>
        </div>
        <div className="mt-2 flex items-center">
          <FaEnvelope className="w-6 h-6 text-gray-700 mr-2" />
          <p className="text-gray-700">info@biztoindia.com</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

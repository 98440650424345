import React from 'react';

const ForgotPassword = () => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      fontSize: '24px', 
      fontWeight: 'bold' 
    }}>
      Coming Soon
    </div>
  );
}

export default ForgotPassword;

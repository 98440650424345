import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const EmailIntegration = () => {
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [secure, setSecure] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [fromName, setFromName] = useState('');
  const [to, setTo] = useState('');
  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    if (port === '587') {
      setSecure('false');
    } else if (port === '465') {
      setSecure('true');
    }
  }, [port]);

  const handlePortChange = (e) => {
    setPort(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://builder.biztoindia.in/api/email-config', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          host,
          port,
          secure: secure === 'true',
          email,
          password,
          fromAddress,
          fromName,
          to,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update email configuration.');
      }

      toast.success('Email configuration updated successfully!');
    } catch (error) {
      toast.error('Failed to update email configuration.');
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto mt-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-900 text-center">
        Seamless Email Configuration
      </h1>
      <p className="mb-6 text-center text-gray-600 ">
        Set up your email server with ease. Just fill in the details below to
        get started with secure, reliable email integration. 
        <span className='text-red-700 underline'>  For gmail make sure your provider is smtp.gmail.com also port is set to be TLS and Enter your App Password in Password Section.</span>
       
      </p>
      <form onSubmit={handleSubmit} className="space-y-4">
        
        {/* Host input */}
        <div>
          <label htmlFor="host" className="block text-sm font-medium text-gray-700">
            SMTP Host
          </label>
          <input
            type="text"
            id="host"
            value={host}
            onChange={(e) => setHost(e.target.value)}
            placeholder="smtp.hostinger.com"
            className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Port selection */}
        <div>
          <label htmlFor="port" className="block text-sm font-medium text-gray-700">
            Port
          </label>
          <select
            id="port"
            value={port}
            onChange={handlePortChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          >
            <option value="" disabled>
              Select port
            </option>
            <option value="587">587 (TLS)</option>
            <option value="465">465 (SSL)</option>
          </select>
        </div>

        {/* Email input */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            SMTP Username (Email)
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Password input */}
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            SMTP Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* From Address input */}
        <div>
          <label htmlFor="fromAddress" className="block text-sm font-medium text-gray-700">
            From Address
          </label>
          <input
            type="email"
            id="fromAddress"
            value={fromAddress}
            onChange={(e) => setFromAddress(e.target.value)}
            placeholder="info@biztoindia.com"
            className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* From Name input */}
        <div>
          <label htmlFor="fromName" className="block text-sm font-medium text-gray-700">
            From Name
          </label>
          <input
            type="text"
            id="fromName"
            value={fromName}
            onChange={(e) => setFromName(e.target.value)}
            placeholder="Bizto India"
            className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* To Address input */}
        <div>
          <label htmlFor="to" className="block text-sm font-medium text-gray-700">
            To Address
          </label>
          <input
            type="email"
            id="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            placeholder="recipient@example.com"
            className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Submit button */}
        <button
          type="submit"
          className="w-full py-2 px-4 bg-red-600 text-white font-semibold rounded-md shadow-sm hover:bg-red-700 focus:outline-none hover:text-black focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Save Configuration
        </button>
      </form>
    </div>
  );
};

export default EmailIntegration;

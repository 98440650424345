 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
 
const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from the route
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/blogs/${id}`); // Adjust endpoint as needed
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        // console.error('Error fetching blog:', error);
        toast.error("Error fetching blog:")
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
      <img
        src={`https://builder.biztoindia.in
        /${blog.thumbnail.path}`} // Adjust path as needed
        alt={blog.title}
        className="w-full h-96 object-cover rounded-md mb-4"
      />
      <p className="text-gray-700 mb-4">{blog.category}</p>
      <div
        className="ql-editor prose max-w-none mx-auto text-gray-800 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: blog.content }}
      />
    </div>
  );
};

export default BlogDetails;

import React, { useState, useRef } from 'react';
import { Toaster, toast } from 'react-hot-toast';

const UploadGallery = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null); // Ref for the file input element

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // 'file' is the key used in the multer setup

    setLoading(true); // Set loading to true during upload

    try {
      const response = await fetch('https://builder.biztoindia.in/api/upload', {
        credentials: 'include',
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('File uploaded successfully! 🎉');
        fileInputRef.current.value = ''; // Reset the file input field in the DOM
        setFile(null); // Reset the file state
      } else {
        toast.error('File upload failed! ' + (data.message || 'Please try again.'));
      }
    } catch (error) {
      toast.error('Oops! Something went wrong during the upload.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <Toaster position="top-center" reverseOrder={false} />
      <h1 className="text-4xl font-bold mb-2 text-gray-800">Upload to Gallery</h1>
      <p className="text-lg text-gray-600 mb-6 text-center">
        Upload images or videos to showcase your creative websites. 
        Let’s make this gallery amazing!
      </p>
      <form className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg border border-gray-300" onSubmit={handleUpload}>
        <label className="block text-gray-700 font-semibold mb-2">
          Choose a file:
          <input
            ref={fileInputRef} // Reference the file input field
            type="file"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500 border border-gray-300 rounded-md p-2 mt-2 focus:ring-2 focus:ring-blue-500 transition duration-200"
            aria-label="File upload"
            accept="image/*,video/*" // Accepting images and videos
          />
        </label>
        <p className="text-gray-600 text-sm mb-4">
          *Please upload only images (JPG, PNG, GIF) or videos (MP4, AVI).
        </p>
        <button
          type="submit"
          className={`w-full py-2 px-4 mt-4 text-white font-semibold rounded-md ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 transition duration-200'}`}
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
    </div>
  );
};

export default UploadGallery;

import { useEffect, useState } from 'react';
import { FaUpload, FaTrash } from 'react-icons/fa'; // Importing trash icon for delete
import { toast, Toaster } from 'react-hot-toast'; // Import react-hot-toast
 

const OurClients = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
 

  useEffect(() => {
    // Fetch existing images
    const fetchImages = async () => {
      try {
        const response = await fetch('https://builder.biztoindia.in/api/clients');
        if (!response.ok) {
          throw new Error('Error fetching images');
        }
        const data = await response.json();
        setUploadedImages(data);
      } catch (error) {
        console.error('Error fetching images:', error);
        toast.error('Error fetching images.');
      }
    };

    fetchImages();
  }, []);

  

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);

    if (newFiles.length > 50) {
      toast.error('You can only upload a maximum of 50 images.');
      return;
    }

    // Filter out duplicate files
    const uniqueNewFiles = newFiles.filter((newFile) =>
      !selectedFiles.some((file) => file.name === newFile.name)
    );

    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]); // Append new files
    setPreviewUrls((prevUrls) => [
      ...prevUrls,
      ...uniqueNewFiles.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (selectedFiles.length === 0) {
      toast.error('No files selected.');
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('images', file);
    });

    try {
      const response = await fetch('https://builder.biztoindia.in/api/clients', {
        credentials: 'include',
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error uploading images');
      }

      const data = await response.json();
      setUploadedImages((prev) => [...prev, data]);
      setSelectedFiles([]); // Reset selected files after upload
      setPreviewUrls([]); // Reset preview after upload

      // Show success notification
      toast.success('Images uploaded successfully!');
    } catch (error) {
      toast.error('Error uploading images. Please try again.');
    }
  };

  const handleDelete = async (clientId, imageUrl) => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/clients/${clientId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl }), // Pass the imageUrl to delete
      });

      if (!response.ok) {
        throw new Error('Error deleting image');
      }

      // Remove the deleted image from the state
      setUploadedImages((prev) =>
        prev.map((client) => ({
          ...client,
          imageUrls: client.imageUrls.filter((url) => url !== imageUrl),
        }))
      );
      toast.success('Image deleted successfully!');
    } catch (error) {
      toast.error('Error deleting image. Please try again.');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <Toaster />
      <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Upload Client Images</h2>
      <p className="text-center text-gray-600 mb-4">
        Use the form below to upload images of your clients. You can upload up to 50 images at a time.
      </p>
      <form onSubmit={handleUpload}>
        <div className="flex flex-col items-center mb-4">
          <label className="cursor-pointer flex items-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300">
            <FaUpload className="mr-2" />
            Select Images
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="hidden"
            />
          </label>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {previewUrls.map((url, index) => (
            <div key={index} className="h-32 w-full bg-gray-200 flex items-center justify-center rounded-lg overflow-hidden shadow-md">
              <img src={url} alt={`Preview ${index}`} className="object-cover w-full h-full" />
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="w-40 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
        >
          Upload Images
        </button>
      </form>

      {/* Display previously uploaded images */}
      <div className="mt-8">
        <h3 className="text-lg font-bold text-gray-800 mb-4">Uploaded Client Images</h3>
        <div className="grid grid-cols-1 gap-4">
          {uploadedImages.map((client, index) => (
            <div key={index}>
              {client.imageUrls.map((url, i) => (
                <div key={i} className="relative w-full h-48 rounded-lg overflow-hidden shadow-md">
                  <img
                    src={`https://builder.biztoindia.in/${url}`}
                    alt={`Client ${i}`}
                    className="object-cover w-full h-full m-2"
                    style={{ aspectRatio: '4/3' }}
                  />
                  {/* Delete button */}
                  <button
                    onClick={() => handleDelete(client._id, url)}
                    className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition duration-300"
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurClients;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast'; // Import react-hot-toast
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS
import { useSelector } from "react-redux";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const user = useSelector(state => state?.user?.user);

  useEffect(() => {
    // Fetch blog posts from the server
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/blogss/${user?._id}`); // Adjust the endpoint as needed
        if (response.ok) {
          const data = await response.json();
          setBlogs(data);
          toast.success("Blogs loaded successfully!"); // Show success notification
        } else {
          throw new Error('Failed to fetch blogs');
        }
      } catch (error) {
        toast.error("Error fetching blogs!"); // Show error notification
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, [user?._id]);

  // Function to format the date in Indian Standard Time (IST)
  const formatDateToIST = (date) => {
    const options = {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: 'Asia/Kolkata',
    };
    return new Date(date).toLocaleDateString('en-IN', options);
  };

  // Function to strip HTML tags from content
  const stripHtmlTags = (htmlString) => {
    const parser = new DOMParser();
    const parsed = parser.parseFromString(htmlString, 'text/html');
    return parsed.body.textContent || ""; // Extracts the text content
  };

  return (
    <>
      <Helmet>
        <title>Blogs</title>
      </Helmet>
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6">Blog Posts</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogs?.map((blog) => (
            <Link
              to={`/blogs/${blog._id}`}
              key={blog._id}
              className="bg-white p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow"
            >
              <img
                src={`https://builder.biztoindia.in/${blog?.thumbnail?.path}`} // Adjust path as needed
                alt={blog.title}
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <h1 className="text-xl font-bold text-gray-900 mb-2">{blog.title}</h1>
              <p className="text-sm text-gray-500 mb-4">{formatDateToIST(blog.createdAt)}</p>

              {/* Highlight category with a red badge */}
              <div className="inline-block bg-red-500 text-white text-xs px-3 py-1 rounded-full mb-4">
                {blog.category}
              </div>

              {/* Strip HTML tags and limit content to 100 characters */}
              <p className="text-gray-700 line-clamp-3">
                {stripHtmlTags(blog.content).substring(0, 100)}...
              </p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogList;

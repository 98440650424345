import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';

const GrantingPermission = () => {
  // State to manage permission checkboxes, including new fields
  const [permissions, setPermissions] = useState({
    showFaq: false,
    showPrivacy: false,
    showShippingPage: false,
    showTermofUsePage: false,
    showRefundPage: false,
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setPermissions((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://builder.biztoindia.in/api/permission-provider', {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(permissions),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      toast.success('Permissions updated successfully!');
    } catch (error) {
      toast.error('Failed to update permissions. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Manage Your Permissions</h2>
        <p className="text-gray-600 mb-4 text-center">
          Customize your experience by selecting the permissions you wish to grant.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              name="showFaq"
              id="showFaq"
              checked={permissions.showFaq}
              onChange={handleInputChange}
              className="mr-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="showFaq" className="text-gray-700">Show FAQ</label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="showPrivacy"
              id="showPrivacy"
              checked={permissions.showPrivacy}
              onChange={handleInputChange}
              className="mr-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="showPrivacy" className="text-gray-700">Show Privacy Policy</label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="showShippingPage"
              id="showShippingPage"
              checked={permissions.showShippingPage}
              onChange={handleInputChange}
              className="mr-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="showShippingPage" className="text-gray-700">Show Shipping Page</label>
          </div>

          {/* New Fields for Term of Use and Refund Policy */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="showTermofUsePage"
              id="showTermofUsePage"
              checked={permissions.showTermofUsePage}
              onChange={handleInputChange}
              className="mr-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="showTermofUsePage" className="text-gray-700">Show Term of Use Page</label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="showRefundPage"
              id="showRefundPage"
              checked={permissions.showRefundPage}
              onChange={handleInputChange}
              className="mr-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="showRefundPage" className="text-gray-700">Show Refund Page</label>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Update Permissions
          </button>
        </form>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: '16px',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
        }}
      />
    </div>
  );
};

export default GrantingPermission;
